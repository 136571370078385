import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, ActivityIndicator } from "react-native";
import { Context as CodeContext } from "@context/CodeContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import Select from "react-select";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import theme from "react-syntax-highlighter/dist/esm/styles/prism/atom-dark";
//import CodeText from "./CodeText";
import colors from "@res/colors";
import fonts from "@res/fonts";

const languages = [
  { name: "cURL", prism: "bash" },
  { name: "JavaScript (fetch)", prism: "javascript" },
  { name: "Python requests", prism: "python" },
  { name: "Ansible URI", prism: "yaml" },

  { name: "Dart", prism: "dart" },
  { name: "Elixir", prism: "elixir" },
  { name: "Go", prism: "go" },
  { name: "Java", prism: "java" },
  //{ name: "JSON", prism: "json" },
  { name: "MATLAB", prism: "matlab" },
  { name: "Node (fetch)", prism: "javascript" },
  { name: "Node (request)", prism: "javascript" },
  { name: "PHP requests", prism: "php" },

  { name: "R httr", prism: "r" },
  { name: "Rust", prism: "rust" },
  { name: "Strest", prism: "yaml" },
];

const selectOptions = languages.map((elem) => {
  return { value: elem.name, label: elem.name };
});

const CodeContainer = ({ botRef, setDidExecute, index }) => {
  const {
    convertRequest,
    setConvertedRequest,
    executeRequest,
    state: { convertedRequest },
  } = useContext(CodeContext);
  const { modDatabase } = useContext(LocalContext);
  const [code, setCode] = useState(curlRequests[index]);
  const [curlRequest, setCurlRequest] = useState(curlRequests[index]);
  const [language, setLanguage] = useState("bash");
  const [vars, setVars] = useState(fieldRequests[index]);
  const [isConverting, setIsConverting] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "cURL",
    label: "cURL",
  });

  useEffect(() => {
    if (convertedRequest != null && convertedRequest != "") {
      setCode(convertedRequest);
      setIsConverting(false);
    }
  }, [convertedRequest]);

  const onExecute = async () => {
    setIsExecuting(true);
    setDidExecute(false);
    await executeRequest({ requestCode: curlRequest });
    setIsExecuting(false);
    botRef.current.scrollIntoView({
      behavior: "smooth",
    });
    setDidExecute(true);
  };

  const onSelectLanguage = (selectedOption) => {
    setSelectedOption(selectedOption);
    const lang = selectedOption.value;
    setIsConverting(true);
    if (lang == "cURL") {
      setLanguage("bash");
      setCode(curlRequest);
      setConvertedRequest(curlRequest);
      setIsConverting(false);
    } else {
      const languageElem = languages.find(
        (elem) => `${elem.name}` == `${lang}`
      );
      setLanguage(languageElem.prism);
      convertRequest({
        requestCode: curlRequest,
        language: languageElem.name,
      });
    }
  };

  const tagComp = (text, color) => (
    <View style={[styles.tag, { backgroundColor: color }]}>
      <Text style={{ color: "white" }} small b font="12px">
        {text}
      </Text>
    </View>
  );

  const onFieldChange = (event, field) => {
    const newValue = `"${event.target.value}"`;

    const varCopy = [...vars];
    const currVar = varCopy.findIndex((elem) => `${elem.key}` == `${field}`);

    let previousValue = vars[currVar].value;
    let replaceValue = `"${event.target.value}"`;
    if (language == "java" || language == "rust") {
      previousValue = `"${previousValue.substring(
        1,
        previousValue.length - 1
      )}\\"`;
      replaceValue = `"${event.target.value}\\"`;
    } else if (language == "matlab") {
      previousValue = `'${previousValue.substring(
        1,
        previousValue.length - 1
      )}'`;
      replaceValue = `'${event.target.value}'`;
    } else if (language == "yaml") {
      previousValue = `: ${previousValue.substring(
        1,
        previousValue.length - 1
      )}`;
      replaceValue = `: ${event.target.value}`;
    }

    // Set cURL req
    const result = curlRequest.replace(vars[currVar].value, newValue);
    setCurlRequest(result);

    // Set code
    const codeResult = code.replace(previousValue, replaceValue);
    setCode(codeResult);

    // Set vars
    varCopy[currVar].value = newValue;
    setVars([...varCopy]);

    if (
      varCopy[currVar].key == "first_name" ||
      varCopy[currVar].key == "last_name"
    ) {
      modDatabase({
        key: "name",
        value: `${varCopy[0].value.substring(
          1,
          varCopy[0].value.length - 1
        )} ${varCopy[1].value.substring(1, varCopy[1].value.length - 1)}`,
      });
    } else if (varCopy[currVar].key == "amount") {
      modDatabase({
        key: "amount",
        value: `${varCopy[currVar].value.substring(
          1,
          varCopy[currVar].value.length - 1
        )}`,
      });
    }
  };

  return (
    <View style={styles.snippetContainer}>
      <View style={styles.fieldContainer}>
        {tagComp("Visual", "black")}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text font="20px" style={{ marginRight: 15 }}>
            Parameters
          </Text>
          <Text style={{ color: "grey" }} small>
            {vars.length} Fields
          </Text>
        </View>

        <Card>
          <ScrollView contentContainerStyle={{ maxHeight: 150 }}>
            {vars.map((item, index) => (
              <View key={`${index}`}>
                <Input
                  label={item.key}
                  placeholder={item.value.substring(1, item.value.length - 1)}
                  onChange={(event) => onFieldChange(event, item.key)}
                />
                <View style={{ marginVertical: 5 }} />
              </View>
            ))}
          </ScrollView>
        </Card>

        <View style={{ marginVertical: 10 }} />
        <Button
          style={{
            alignSelf: "flex-start",
            backgroundColor: "#4f41f1",
            borderColor: "#4f41f1",
          }}
          auto
          type="secondary-light"
          onClick={() => onExecute()}
          loading={isExecuting}
        >
          Create
        </Button>
      </View>
      <View style={styles.codeContainer}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {tagComp("Code", "black")}
          <View style={{ width: 150, alignSelf: "flex-end" }}>
            <Select
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                control: (base) => ({
                  ...base,
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid black",
                  },
                  "&:focus": {
                    borderColor: "black",
                  },
                  "&:active": {
                    borderColor: "black",
                  },
                  fontSize: 12,
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: 12,
                }),
              }}
              placeholder="Language"
              onChange={onSelectLanguage}
              value={selectedOption}
              options={selectOptions}
            />
          </View>
        </View>
        <View>
          <SyntaxHighlighter
            language={language}
            style={theme}
            customStyle={{
              borderWidth: 0,
              height: 280,
              borderRadius: 10,
              width: "100%",
            }}
          >
            {code}
          </SyntaxHighlighter>
          {isConverting ? (
            <View
              style={{
                position: "absolute",
                bottom: 0,
                top: 0,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ActivityIndicator color="white" />
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tag: {
    alignSelf: "flex-start",
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  snippetContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  fieldContainer: {
    width: "42%",
  },
  codeContainer: {
    width: "55%",
  },
});

export default CodeContainer;
