import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Dot } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";

const loadedStyles = JSON.stringify({
  container: {
    alignSelf: "center",
    width: 300,
    height: 450,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,

    elevation: 9,
  },
});

const Success = ({ component }) => {
  const {
    state: { localDatabase },
  } = useContext(LocalContext);

  return (
    <View>
      {/* <JsxParser
        bindings={{
          styles,
          localDatabase,
        }}
        components={{
          View,
          ScrollView,
          motion,
          ActivityIndicator,
          Text,
          Button,
          Tag,
          TouchableOpacity,
          Card,
          Input,
          Ionicons,
          Image,
        }}
        jsx={component}
      /> */}
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: localDatabase.endpoint.primary_color,
            borderTopWidth: 3,
          },
        ]}
      >
        <View style={{ width: 300, justifyContent: "space-between" }}>
          <View
            style={{
              backgroundColor: "#DEFFF6",
              paddingVertical: 8,
              paddingHorizontal: 15,
              borderRadius: 50,
              position: "absolute",
              top: 0,
              left: 0,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                height: 8,
                width: 8,
                borderRadius: 50,
                backgroundColor: localDatabase.endpoint.primary_color,
                marginRight: 6,
              }}
            />
            <Text
              style={{ color: localDatabase.endpoint.primary_color }}
              small
              b
              font="10px"
            >
              Active
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
              alignItems: "center",
              marginBottom: 25,
            }}
          >
            <View
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Ionicons
                name="archive"
                size={24}
                color={localDatabase.endpoint.primary_color}
              />
            </View>
          </View>

          <Card style={{ width: 300, alignSelf: "center", marginBottom: 25 }}>
            <View
              style={{
                width: "100%",
                alignItems: "center",
                alignSelf: "center",

                marginBottom: 25,
              }}
            >
              <Text
                style={{ color: "grey", marginBottom: 10 }}
                small
                b
                font="12px"
              >
                Keyword Picture
              </Text>
              <Image
                source={{
                  uri: localDatabase.endpoint.picture_keyword,
                }}
                style={{
                  height: 250,
                  width: 250,
                  borderRadius: 10,
                  backgroundColor: "black",
                  marginBottom: 15,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Text style={{ color: "grey" }} small b font="12px">
                Piglatin Translation
              </Text>
              <Text style={{ marginBottom: -10, textAlign: "center" }} h6>
                {localDatabase.endpoint.piglatin_translate}
              </Text>
            </View>
          </Card>

          <TouchableOpacity
            style={{
              backgroundColor: localDatabase.endpoint.primary_color,
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              flexDirection: "row",
            }}
          >
            <Ionicons
              name="ios-checkmark"
              size={24}
              color="white"
              style={{ marginRight: 5 }}
            />
            <Text style={{ color: "white" }} small b>
              Executed Successfully
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

//JSON.parse(loadedStyles)

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Success;
