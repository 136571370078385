import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text } from "@geist-ui/react";
import RemoteImage from "../../../components/RemoteImage";
import Navigation from "./Navigation";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Entypo } from "@expo/vector-icons";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./customStyle.css";

const Sidebar = ({ collapsed, setCollapsed }) => {
  const {
    state: { tabs, selectedTab },
  } = useContext(PlanContext);

  const collapsedComp = () => (
    <TouchableOpacity
      onPress={() => setCollapsed(!collapsed)}
      style={{
        borderColor: "grey",
        borderWidth: 1,
        padding: 6,
        borderRadius: 8,
        alignSelf: "center",
      }}
    >
      <Entypo name="menu" size={22} color="white" />
    </TouchableOpacity>
  );

  const fullComp = () => (
    <>
      <SidebarHeader style={{ marginBottom: 50 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <TouchableOpacity
            onPress={() => window.open("https://www.trycoast.com", "_blank")}
          >
            <RemoteImage
              desiredWidth={100}
              uri="https://i.ibb.co/k1BG3KK/Coast-Logo-White.png"
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
            <Entypo
              name="chevron-with-circle-left"
              size={22}
              color="white"
              style={{ marginRight: 15 }}
            />
          </TouchableOpacity>
        </View>
        <View style={{ marginVertical: 25 }} />
        <View>
          <Text style={{ color: "white", lineHeight: 0 }} font="16px">
            Acme, Inc.
          </Text>
          <Text style={{ color: "#4f41f1", lineHeight: 0 }} font="16px">
            Integration Playground
          </Text>
        </View>
      </SidebarHeader>
      <SidebarContent style={{ marginBottom: 50 }}>
        <Navigation />
      </SidebarContent>
      <SidebarFooter>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
          onPress={() => window.open("mailto:hey@trycoast.com")}
        >
          <Entypo
            name="mail"
            size={16}
            color="white"
            style={{ marginRight: 10 }}
          />
          <Text style={{ color: "white" }} font="12px" b>
            HEY@TRYCOAST.COM
          </Text>
        </TouchableOpacity>
      </SidebarFooter>
    </>
  );

  return (
    <View
      style={!collapsed ? styles.container : { width: "100%", paddingTop: 25 }}
    >
      <ProSidebar
        collapsed={collapsed}
        style={{
          height: "100%",
          backgroundColor: "#0e0026",
          width: !collapsed ? "100%" : null,
        }}
      >
        {collapsed ? collapsedComp() : fullComp()}
      </ProSidebar>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    paddingTop: 25,
    paddingLeft: 25,
    paddingRight: 0,
    paddingBottom: 25,
    justifyContent: "space-between",
  },
  companyLogo: {
    width: "50%",
    height: 100,
  },
});

export default Sidebar;
