import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";

const LocalDisplay = ({ order, stepContent }) => {
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [dataLoaded, setDataLoaded] = useState(null);

  const selectedOption = localDatabase.select;

  useEffect(() => {
    const refreshStockData = async () => {
      if (selectedOption != null) {
        setDataLoaded(null);
        try {
          const response = await axios.get(
            `https://finnhub.io/api/v1/quote?symbol=${selectedOption.ticker}&token=c8jdrlqad3i9dlebcva0`
          );
          console.log(response.data);
          setDataLoaded(response.data);
          modDatabase({
            key: "value",
            value: response.data,
          });
        } catch (e) {
          toast.error("Something went wrong fetching stock data");
        }
      }
    };
    refreshStockData();
  }, [selectedOption]);

  const getTableData = () => {
    const data = [
      {
        key: "Current Price",
        value: `$${dataLoaded.c}`,
      },
      {
        key: "Change",
        value: `$${dataLoaded.d}`,
      },
      {
        key: "Percent Change",
        value: `${dataLoaded.dp}%`,
      },
      {
        key: "High of Day",
        value: `$${dataLoaded.h}`,
      },
    ];

    return data;
  };

  return (
    <View style={{ width: "100%", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: "#4f41f1",
            borderTopWidth: 3,
            width: "70%",
          },
        ]}
      >
        {!dataLoaded || dataLoaded == null ? (
          <View>
            <ActivityIndicator style={{ alignSelf: "center" }} />
            <Text style={{ color: "grey", textAlign: "center" }} p>
              No chain selected
            </Text>
          </View>
        ) : (
          <View style={{ width: "100%", justifyContent: "space-between" }}>
            <View style={{ marginBottom: 25 }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: -10,
                }}
              >
                <View
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 50,
                    backgroundColor: "black",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 10,
                    marginTop: 6,
                  }}
                >
                  <Ionicons name="analytics" size={11} color="white" />
                </View>
                <Text h4>{localDatabase.select.name}</Text>
              </View>
            </View>

            <Card
              style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  alignSelf: "center",

                  marginBottom: 25,
                }}
              >
                <Image
                  source={{
                    uri: localDatabase.select.logo,
                  }}
                  style={{
                    height: 50,
                    width: 50,
                    borderRadius: 50,
                    backgroundColor: "black",
                    marginBottom: 15,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <Text style={{ color: "grey" }} small b font="12px">
                  Stock Ticker
                </Text>
                <Text style={{ marginBottom: -10, textAlign: "center" }} h4>
                  {localDatabase.select.ticker}
                </Text>
                <View style={{ marginVertical: 10 }} />
                <Table data={getTableData()}>
                  <Table.Column prop="key" label="Key" />
                  <Table.Column prop="value" label="Value" />
                </Table>
              </View>
              <TouchableOpacity
                style={{
                  backgroundColor: "#4f41f1",
                  width: "100%",
                  paddingVertical: 8,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 5,
                  flexDirection: "row",
                }}
              >
                <Ionicons
                  name="ios-checkmark"
                  size={24}
                  color="white"
                  style={{ marginRight: 5 }}
                />
                <Text style={{ color: "white" }} small b>
                  Data Fetched
                </Text>
              </TouchableOpacity>
            </Card>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default LocalDisplay;
