import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";

const TextView = () => {
  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          marginTop: 150,
          borderRadius: 10,
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            "#5b4dff" +
            ", " +
            "#5C258D" +
            ")",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <View style={{ width: "60%", paddingVertical: 30 }}>
          <Text
            style={{ color: "white", paddingBottom: 0, marginBottom: 0 }}
            h1
          >
            Customizations
          </Text>
          <Text style={{ color: "white", opacity: 0.65 }} p>
            What you saw above is just the tip of the iceberg. We can make
            custom, supercharged components specific to your API to give
            prospects the most interactive and personal sales experience they've
            ever seen. A couple more examples to jog some inspiration: let users
            upload their own multimedia to execute an action, like OCR data
            extraction or speech-to-text capabilities. If you’re a Fintech API,
            we can help you let users send themselves a dollar to their bank
            account in real time! Or if you’re a universal API and want to show
            off how easy it is aggregate diverse data from just one call, Coast
            is right for you. As you can see, the opportunities are truly
            endless.
          </Text>
        </View>
        <View>
          <Image
            source={require("../../../../../assets/images/gift-dynamic-clay.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default TextView;
