import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
  Text,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as CodeContext } from "@context/CodeContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import Overview from "./Views/Overview/Overview";
import Goal from "./Views/Goal/Goal";
import Step from "./Views/Step/Step";
import NextSteps from "./Views/NextSteps/NextSteps";
import TextView from "./Views/TextView";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { InView } from "react-intersection-observer";
import _ from "lodash";

const Content = () => {
  const {
    selectTab,
    state: { tabs, selectedTab },
  } = useContext(PlanContext);
  const {
    state: { requestResponse },
  } = useContext(CodeContext);
  const { modDatabase } = useContext(LocalContext);
  const scrollRef = useRef(scrollRef);
  const elementsRef = useRef(tabs.map(() => createRef()));
  const [scrolling, setScrolling] = useState(null);
  const windowDims = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const scrollAction = async () => {
      if (
        elementsRef.current &&
        elementsRef.current[selectedTab.index] &&
        elementsRef.current[selectedTab.index].current &&
        selectedTab.type == "manual"
      ) {
        setScrolling(selectedTab.index);
        await elementsRef.current[selectedTab.index].current.scrollIntoView({
          behavior: "smooth",
        });
      }
    };
    scrollAction();
  }, [selectedTab]);

  useEffect(() => {
    if (requestResponse != null) {
      console.log(requestResponse);
      modDatabase({
        key: requestResponse.localTitle,
        value: _.cloneDeep(requestResponse.data),
      });
    }
  }, [requestResponse]);

  const onViewChange = (inView, entry, index) => {
    if (inView) {
      if (scrolling != null) {
        if (index == scrolling) {
          setScrolling(null);
        }
      } else {
        selectTab({ index, type: "auto" });
      }
    }
  };

  return (
    <ScrollView
      ref={scrollRef}
      id="scrollContent"
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
    >
      {/* {selectedTabContent.type == 0 ? <Overview /> : null}
      {selectedTabContent.type == 1 ? <Goal /> : null}
      {selectedTabContent.type == 2 ? <Step /> : null} */}

      {/* <TouchableOpacity
        onPress={() => {
          if (
            elementsRef.current &&
            elementsRef.current[2] &&
            elementsRef.current[2].current
          ) {
            setScrolling(2);
            elementsRef.current[2].current.scrollIntoView({
              behavior: "smooth",
            });
          }
        }}
        style={{ backgroundColor: "red", width: 200, height: 200 }}
      ></TouchableOpacity> */}

      {tabs.map((elem, index) => {
        if (elem.type == 0) {
          return (
            <View
              style={{
                marginBottom: 100,
              }}
              key={`${elem.title}`}
              ref={elementsRef.current[index]}
            >
              <InView
                threshold={0.2}
                onChange={(inView, entry) => onViewChange(inView, entry, index)}
              >
                <Overview order={index} />
              </InView>
            </View>
          );
        } else if (elem.type == 1) {
          return (
            <View ref={elementsRef.current[index]} key={`${elem.title}`}>
              <InView
                threshold={0.2}
                onChange={(inView, entry) => onViewChange(inView, entry, index)}
              >
                <View style={{ marginTop: 50 }} />
                <Goal order={index} />
                <View style={{ marginVertical: 100 }} />
              </InView>
            </View>
          );
        } else if (elem.type == 2) {
          return (
            <View ref={elementsRef.current[index]} key={`${elem.title}`}>
              <InView
                threshold={0.2}
                onChange={(inView, entry) => onViewChange(inView, entry, index)}
              >
                {({ inView, ref, entry }) => (
                  <View ref={ref}>
                    <View style={{ marginTop: 50 }} />

                    <Step
                      isVisible={inView}
                      order={index}
                      scrollRef={scrollRef}
                      isScrolling={scrolling}
                    />

                    <View style={{ marginVertical: 100 }} />
                  </View>
                )}
              </InView>
            </View>
          );
        } else if (elem.type == 3) {
          return (
            <View ref={elementsRef.current[index]} key={`${elem.title}`}>
              <InView
                threshold={0.2}
                onChange={(inView, entry) => onViewChange(inView, entry, index)}
              >
                <View style={{ marginTop: 100 }} />
                <NextSteps order={index} />
                <View style={{ marginVertical: 100 }} />
              </InView>
            </View>
          );
        } else if (elem.type == 4) {
          return (
            <View ref={elementsRef.current[index]} key={`${elem.title}`}>
              <InView
                threshold={0.2}
                onChange={(inView, entry) => onViewChange(inView, entry, index)}
              >
                <TextView order={index} />
              </InView>
            </View>
          );
        }
      })}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 0,
    height: "96%",
    width: "98.5%",
    backgroundColor: "white",
    borderRadius: 20,
  },
  content: {
    width: "100%",
    paddingTop: 50,
    paddingLeft: 50,
    paddingBottom: 50,
    paddingRight: 25,
    height: "100%",
  },
});

export default Content;
