import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, Image, useWindowDimensions } from "react-native";
import { Text } from "@geist-ui/react";
import OverviewContent from "./OverviewContent";
import colors from "@res/colors";
import fonts from "@res/fonts";

const Overview = () => {
  return (
    <View style={{ paddingTop: 50 }}>
      <View style={styles.container}>
        <View style={styles.letterContainer}>
          <Text style={{ color: "#4f41f1" }} font="20px" b>
            Acme, Inc.
          </Text>
          <Text style={{ color: "#242424" }} font="26px" b>
            Hey, there!
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: "#9C9C9C" }} font="16px">
            Thanks for taking the time to check out Coast. We put this working
            demo together to show you just a few of the ways you can use our
            tool.
          </Text>
          <Text style={{ color: "#9C9C9C" }} font="16px">
            If you see something you hate, scrap it! See something you love?
            Build on top of it! Let us help you create the perfect customized
            sales materials for outbound, live demos and follow ups.
          </Text>
          <Text style={{ color: "#9C9C9C" }} font="16px">
            Best, The Coast Team
          </Text>
        </View>

        <View
          style={[
            styles.overviewContainer,
            {
              background:
                "linear-gradient(" +
                90 +
                "deg, " +
                "#5b4dff" +
                ", " +
                "#3a7bd5" +
                ")",
            },
          ]}
        >
          <OverviewContent />
        </View>
      </View>
      <View
        style={{
          width: "90%",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          marginTop: 150,
          borderRadius: 10,
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            "#00d2ff" +
            ", " +
            "#5C258D" +
            ")",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <View style={{ width: "60%", paddingVertical: 30 }}>
          <Text
            style={{ color: "white", paddingBottom: 0, marginBottom: 0 }}
            h1
          >
            Acme, Inc.'s Test Drive
          </Text>
          <Text style={{ color: "white", opacity: 0.65 }} p>
            Coast comes fully integrated with your API, so your leads can try
            out all you have to offer! There’s no local environment set up, no
            cloning repos, and no setting up Postman collections. We're the
            fastest way for your prospects to interact with your API.
          </Text>
        </View>
        <View>
          <Image
            source={require("../../../../../../assets/images/rocket-dynamic-clay.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  letterContainer: {
    width: "30%",
  },
  overviewContainer: {
    width: "50%",
    padding: 50,
    maxWidth: 500,
    borderRadius: 5,
  },
  background: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    height: "100%",
    zIndex: -1,
  },
});

export default Overview;
