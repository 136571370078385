import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import Fuse from "fuse.js";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} resizeMode="cover" />;
});

const LocalFilter = ({ order, stepContent }) => {
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [dataLoaded, setDataLoaded] = useState(null);
  const [fuse, setFuse] = useState(null);
  const [queryData, setQueryData] = useState(null);

  const selectedOption = localDatabase.select;

  useEffect(() => {
    const refreshStockData = async () => {
      if (selectedOption != null) {
        setDataLoaded(null);
        try {
          const response = await axios.get(
            `https://finnhub.io/api/v1/company-news?symbol=${selectedOption.ticker}&from=2022-03-01&to=2022-03-05&token=c8jdrlqad3i9dlebcva0`
          );
          console.log(response.data);
          setDataLoaded(response.data);
          const fuseOb = new Fuse(response.data, {
            keys: ["headline", "category"],
          });
          setFuse(fuseOb);
          setQueryData(fuseOb.search(""));
          modDatabase({
            key: "filter",
            value: response.data,
          });
        } catch (e) {
          toast.error("Something went wrong fetching stock data");
        }
      }
    };
    refreshStockData();
  }, [selectedOption]);

  const onSearch = (e) => {
    const query = e.target.value;
    const output = fuse.search(query);
    setQueryData(output);
  };

  const listData =
    !queryData || queryData == null || queryData.length == 0
      ? dataLoaded
      : queryData;

  return (
    <View style={{ width: "100%", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: "#4f41f1",
            borderTopWidth: 3,
            width: "70%",
          },
        ]}
      >
        {!dataLoaded || dataLoaded == null || queryData == null ? (
          <View>
            <ActivityIndicator style={{ alignSelf: "center" }} />
            <Text style={{ color: "grey", textAlign: "center" }} p>
              No chain selected
            </Text>
          </View>
        ) : (
          <View style={{ width: "100%", justifyContent: "space-between" }}>
            <View style={{ marginBottom: 25 }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: -10,
                }}
              >
                <View
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 50,
                    backgroundColor: "black",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 10,
                    marginTop: 6,
                  }}
                >
                  <Ionicons name="analytics" size={11} color="white" />
                </View>
                <Text h4>{localDatabase.select.name}</Text>
              </View>
            </View>

            <Input
              width="100%"
              placeholder="Filter Articles..."
              onChange={(e) => onSearch(e)}
            />
            <View style={{ marginBottom: 15 }} />

            <View
              style={{
                borderColor: "lightgrey",
                borderWidth: 0.5,
                borderRadius: 5,
                marginBottom: 20,
              }}
            >
              <ScrollView
                contentContainerStyle={{
                  maxHeight: 450,
                  paddingTop: 15,
                  paddingHorizontal: 15,
                }}
              >
                {listData.map((ob) => {
                  const elem = ob.item != null ? ob.item : ob;
                  if (elem.image != null && elem.image != "") {
                    return (
                      <TouchableOpacity
                        key={`${elem.id}`}
                        onPress={() => window.open(elem.url, "_blank")}
                        style={{
                          marginVertical: 15,
                          marginHorizontal: 15,
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            height: 250,
                            borderRadius: 8,
                            padding: 30,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Text style={{ color: "lightgrey" }} small>
                            {elem.source}
                          </Text>
                          <Text style={{ color: "white" }} h4>
                            {elem.headline.substring(0, 70)}...
                          </Text>
                          <Text style={{ color: "white" }} small>
                            {elem.summary.substring(0, 100)}...
                          </Text>
                          <View
                            style={{
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              zIndex: -1,
                              backgroundColor: "rgba(0,0,0,0.8)",
                              borderRadius: 8,
                            }}
                          />
                          <MemoImage
                            source={{ uri: elem.image }}
                            style={{
                              width: "100%",
                              height: 250,
                              borderRadius: 8,
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              zIndex: -2,
                            }}
                          />
                        </View>
                      </TouchableOpacity>
                    );
                  }
                })}
              </ScrollView>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default LocalFilter;
