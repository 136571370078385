import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, ActivityIndicator } from "react-native";
import { Context as CodeContext } from "@context/CodeContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Textarea, Button, Card } from "@geist-ui/react";
import { Ionicons } from "@expo/vector-icons";
import _ from "lodash";

import colors from "@res/colors";
import fonts from "@res/fonts";

const CodeFields = ({
  onExecute,
  isExecuting,
  curlRequest,
  requestFields,
  changedVars,
  executeTrigger,
}) => {
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [fieldRequest, setFieldRequest] = useState(curlRequest);
  const [vars, setVars] = useState(requestFields);

  useEffect(() => {
    const changePreloads = () => {
      const varsClone = _.cloneDeep(vars);
      changedVars.forEach((elem) => {
        const varIndex = varsClone.findIndex(
          (subElem) => `${subElem.key}` == `${elem.key}`
        );

        varsClone[varIndex].value = `${elem.value}`;
        setVars([...varsClone]);
      });
    };
    if (changedVars.length > 0) changePreloads();
  }, [changedVars]);

  useEffect(() => {
    if (executeTrigger == true) {
      onExecuteWithStorage(fieldRequest);
    }
  }, [executeTrigger]);

  const onExecuteWithStorage = (req) => {
    onExecute(vars);
    // vars.forEach((elem) => {
    //   modDatabase({
    //     key: elem.key,
    //     value: `${elem.value}`,
    //   });
    // });
  };

  const onFieldChange = (event, field) => {
    const newValue = `${event.target.value}`;

    const varCopy = [...vars];
    const currVar = varCopy.findIndex((elem) => `${elem.key}` == `${field}`);

    // Set cURL req
    const result = fieldRequest.replace(
      `"${vars[currVar].value}"`,
      `"${newValue}"`
    );
    setFieldRequest(result);

    // Set code
    // const codeResult = code.replace(previousValue, replaceValue);
    // setCode(codeResult);

    // Set vars
    varCopy[currVar].value = newValue;
    setVars([...varCopy]);
  };

  return (
    <View style={{ alignSelf: "center", width: "100%", paddingHorizontal: 10 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text font="20px" style={{ marginRight: 15 }}>
          Parameters
        </Text>
        <Text style={{ color: "grey" }} small>
          {vars.length} Fields
        </Text>
      </View>

      <Card>
        <ScrollView
          contentContainerStyle={{
            maxHeight: 150,
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {vars.map((item, index) => {
            return (
              <View
                key={`${item.key}`}
                style={{
                  width: item.multiline == null ? "49%" : "92%",
                  minWidth: 100,
                }}
              >
                {item.multiline != null && item.multiline == true ? (
                  <View>
                    <Textarea
                      placeholder={item.label}
                      onChange={(event) => onFieldChange(event, item.key)}
                    />
                    <View style={{ marginVertical: 25 }} />
                  </View>
                ) : (
                  <View>
                    <Input
                      placeholder={item.value}
                      onChange={(event) => onFieldChange(event, item.key)}
                    >
                      {item.label}
                    </Input>
                    <View style={{ marginVertical: 25 }} />
                  </View>
                )}
              </View>
            );
          })}
        </ScrollView>
      </Card>

      <View style={{ marginVertical: 10 }} />
      <Button
        style={{
          alignSelf: "flex-start",
          backgroundColor: "#4f41f1",
          borderColor: "#4f41f1",
        }}
        auto
        type="secondary-light"
        onClick={() => onExecuteWithStorage(fieldRequest)}
        loading={isExecuting}
      >
        Work Some Magic
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({});

export default CodeFields;
