import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Select } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import StickyBox from "react-sticky-box";
import CodeContainer from "./CodeContainer";
import RequestTabs from "./RequestTabs";
import StepBot from "./StepBot";
import Success from "./Success";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { toast } from "react-toastify";

const Endpoint = ({ order, stepContent, scrollRef, isScrolling }) => {
  const {
    state: { gradients, tabs },
  } = useContext(PlanContext);
  const { modDatabase } = useContext(LocalContext);
  const botRef = useRef(null);
  const [didExecute, setDidExecute] = useState(false);
  const [triggerInView, setTriggerInView] = useState(false);
  const [executeTrigger, setExecuteTrigger] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const windowDims = useWindowDimensions();

  const lineVariants = {
    visible: { display: "block", height: "auto", opacity: 1 },
    hidden: { display: "none", height: 0, opacity: 0 },
  };

  const handleTrigger = async () => {
    if (isScrolling == null && scrollDown == true) {
      // await stepContent.content.requestFields.forEach((elem) => {
      //   modDatabase({
      //     key: elem.key,
      //     value: `${elem.value.substring(1, elem.value.length - 1)}`,
      //   });
      // });
      // botRef.current.scrollIntoView({
      //   behavior: "smooth",
      // });
      // setDidExecute(true);
      // toast("Request Executed");
      setExecuteTrigger(true);
    }
  };

  useEffect(() => {
    let listenerAdded = false;
    var lastScrollTop = 0;

    const handleScroll = () => {
      var st = scrollRef.current.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop) {
        setScrollDown(true);
      } else {
        setScrollDown(false);
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    };

    if (scrollRef.current != null) {
      scrollRef.current.addEventListener("scroll", handleScroll);
      listenerAdded = true;
    }
    return () => {
      if (listenerAdded == true && scrollRef.current != null)
        scrollRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef]);

  return (
    <View style={{ width: "100%", minHeight: 1400 }}>
      <RequestTabs
        order={order}
        botRef={botRef}
        setDidExecute={setDidExecute}
        executeTrigger={executeTrigger}
      />
      {!didExecute ? (
        <InView
          threshold={1}
          onChange={(inView) => {
            if (inView == true) {
              setTriggerInView(true);
              handleTrigger();
            }
          }}
          style={{ position: "absolute", top: 0.7 * windowDims.width }}
        >
          <View
            style={{
              backgroundColor: "red",
              opacity: 0,
              height: 50,
              width: 50,
            }}
          />
        </InView>
      ) : null}
      <motion.div
        animate={{ opacity: didExecute ? 1 : 0 }}
        style={{ alignSelf: "center" }}
        ref={botRef}
      >
        <View style={{ alignSelf: "center", marginTop: 20 }}>
          {didExecute ? (
            <motion.div
              variants={lineVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 2 }}
              style={{ alignSelf: "center" }}
            >
              <View
                style={{
                  width: 1,
                  height: 75,
                  borderLeftWidth: 1,
                  borderLeftColor: "grey",
                  borderStyle: "dashed",
                  alignSelf: "center",
                }}
              />
            </motion.div>
          ) : null}
        </View>

        <View style={[styles.botContainer, { background: gradients[order] }]}>
          <motion.div drag dragElastic={0.2} dragConstraints={botRef}>
            <StepBot
              didExecute={didExecute}
              botDetails={stepContent.content.botDetails}
            />
          </motion.div>
        </View>

        {didExecute ? (
          <View style={{ alignSelf: "center", marginTop: 20 }}>
            <motion.div
              variants={lineVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 2, delay: [1, 2, 3].length * 2 + 1 }}
              style={{ alignSelf: "center" }}
            >
              <View
                style={{
                  width: 1,
                  height: 75,
                  borderLeftWidth: 1,
                  borderLeftColor: "grey",
                  borderStyle: "dashed",
                  alignSelf: "center",
                }}
              />
            </motion.div>
            <View style={{ marginVertical: 20, width: "100%" }}>
              <motion.div
                variants={lineVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 2, delay: [1, 2, 3].length * 2 + 2 }}
                style={{ alignSelf: "center" }}
              >
                <Success component={stepContent.content.successCode} />
              </motion.div>
            </View>
          </View>
        ) : null}
      </motion.div>
    </View>
  );
};

const styles = StyleSheet.create({
  botContainer: {
    alignSelf: "center",
    width: 400,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    padding: 35,
  },
  botBackground: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    zIndex: -1,
  },
  dashedLine: {
    alignSelf: "center",
    borderLeftWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: 0,
  },
});

export default Endpoint;
