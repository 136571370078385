import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import {
  Text,
  Button,
  Tag,
  Card,
  Input,
  Table,
  Snippet,
  Rating,
  Collapse,
  Slider,
} from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import StickyBox from "react-sticky-box";
import { Ionicons } from "@expo/vector-icons";

const NextSteps = () => {
  const [value, setValue] = useState(1);
  const [locked, setLocked] = useState(false);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={{ color: "#D9D9D9", lineHeight: 1.1 }} h1>
          Wrapping Up
        </Text>
        <Text style={{ color: "#242424", lineHeight: 1.1 }} h1>
          Next Steps
        </Text>
        <View style={{ marginVertical: 15 }} />
        <View style={{ width: "75%" }}>
          <Text style={{ color: "#8C8C8C" }} font="16px">
            This is where you can provide some action items. Embed a Calendly,
            prompt users to share the link with other team members across
            departments, leave an input field to collect feedback or questions,
            and more.
          </Text>
        </View>
      </View>
      <View style={styles.content}>
        <View style={{ alignSelf: "flex-start" }}>
          <Snippet
            symbol=""
            text="https://trycoast.com/demo"
            type="dark"
            width="300px"
          />
        </View>
        <Card>
          <View>
            <Text small>Leave a Rating</Text>
            <View style={{ marginVertical: 5 }} />
            <Rating value={value} onValueChange={setValue} />
          </View>
        </Card>
        <Card>
          <View>
            <Text small>On a Scale of 1-100, how much do you love Coast?</Text>
            <View style={{ marginVertical: 5 }} />
            <Slider initialValue={50} min={1} />
          </View>
        </Card>
      </View>
      <Collapse.Group>
        <Text h5>Example FAQ</Text>
        <Collapse title="How can I get started with Coast?">
          <TouchableOpacity
            onPress={() => window.open("https://www.trycoast.com", "_blank")}
          >
            <Text>
              Send us an email at hey@trycoast.com or put your email in the form
              on the bottom of our page (https://www.trycoast.com/). We’ll be in
              touch ASAP!
            </Text>
            <Text style={{ color: "#4f41f1" }} b>
              Click here!
            </Text>
          </TouchableOpacity>
        </Collapse>
        <Collapse title="Can I generate a Coast demo environment for every sales lead?">
          <Text>
            Yes, absolutely! We make it super easy for you to generate custom
            materials for outbound, live demos and follow ups. It should take no
            longer than the time it takes to send a follow up email 🚀. We’ll
            take care of the all the upfront set up time ourselves!
          </Text>
        </Collapse>
      </Collapse.Group>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  content: {
    marginTop: 20,
    marginBottom: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});

export default NextSteps;
