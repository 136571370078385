import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} />;
});

const LocalSelect = ({ order, stepContent }) => {
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);

  const onSelect = (chain) => {
    modDatabase({ key: "select", value: chain });
  };

  return (
    <View style={{ width: "100%", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          { borderTopColor: "#4f41f1", borderTopWidth: 3, width: "70%" },
        ]}
      >
        <View style={{ marginBottom: 25 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <Ionicons
              name="business"
              size={25}
              color="black"
              style={{ marginRight: 8 }}
            />
            <Text h4>Select Your Favorite Chain</Text>
          </View>
          <Text style={{ color: "grey" }} font="11px">
            We'll save this to your database
          </Text>
        </View>

        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxHeight: 300,
              paddingTop: 15,
              paddingHorizontal: 15,
            }}
          >
            {stepContent.content.data.map((elem) => {
              const isSelected =
                localDatabase.select != null &&
                `${localDatabase.select.name}` == `${elem.name}`;

              return (
                <TouchableOpacity
                  key={`${elem.name}`}
                  onPress={() => onSelect(elem)}
                  style={{
                    marginVertical: 15,
                    marginHorizontal: 15,
                    width: "25%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <motion.div whileHover={{ scale: 1.2 }} layout>
                    <MemoImage
                      source={{ uri: elem.logo }}
                      style={{
                        width: isSelected ? 75 : 60,
                        height: isSelected ? 75 : 60,
                        borderRadius: 60,
                        borderColor: isSelected ? "#4f41f1" : "lightgrey",
                        borderWidth: isSelected ? 4 : 1,
                        marginBottom: 8,
                      }}
                    />
                  </motion.div>
                  <Text
                    small
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                    font="12px"
                  >
                    {elem.name}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
        {!localDatabase.select || localDatabase.select == null ? (
          <Button auto type="error">
            No Merchant Selected
          </Button>
        ) : (
          <Button auto type="success">
            {localDatabase.select.name} Selected
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default LocalSelect;
