import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import GoalBot from "./GoalBot";

const loadedStyles = JSON.stringify({
  animationContainer: {
    alignSelf: "center",
    width: 300,
    height: 450,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,

    elevation: 9,
  },
});

const component = `<View style={styles.animationContainer}></View>`;

const profileArr = [
  {
    key: "Name",
    value: "Acme, Inc.",
    icon: "finger-print",
  },
  {
    key: "Type",
    value: "Cool Company",
    icon: "earth",
  },
  {
    key: "Needs",
    value: "Coast",
    icon: "airplane",
  },
];

const Goal = () => {
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  const successOne = () => (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: "#4f41f1", borderTopWidth: 3 },
      ]}
    >
      <View style={{ width: 250, justifyContent: "space-between" }}>
        <View
          style={{
            alignSelf: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
        >
          <View
            style={{
              height: 50,
              width: 50,
              borderRadius: 50,
              backgroundColor: "black",
              marginBottom: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Ionicons name="person-outline" size={24} color="#00d2ff" />
          </View>
          <Text style={{ marginBottom: -10, textAlign: "center" }} h6>
            Acme, Inc.
          </Text>
          <Text style={{ color: "grey", textAlign: "center" }} font="12px">
            Company ID: 'ent_msb8G2Qm7sZDd'
          </Text>
        </View>

        <Card style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}>
          <View
            style={{
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              {profileArr.map((elem, index) => (
                <View
                  key={elem.key}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: index == [1, 2, 3, 4].length ? 0 : 10,
                  }}
                >
                  <Ionicons
                    name={elem.icon}
                    size={12}
                    color="lightgrey"
                    style={{ marginRight: 6 }}
                  />
                  <Text style={{ color: "grey" }} small font="11px">
                    {elem.key}
                  </Text>
                </View>
              ))}
            </View>
            <View>
              {profileArr.map((elem, index) => (
                <View
                  key={elem.value}
                  style={{
                    marginBottom: index == [1, 2, 3, 4].length ? 0 : 10,
                  }}
                >
                  <Text small font="11px">
                    {elem.value}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </Card>

        <TouchableOpacity
          style={{
            backgroundColor: "#4f41f1",
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            flexDirection: "row",
          }}
        >
          <Ionicons
            name="ios-checkmark"
            size={24}
            color="white"
            style={{ marginRight: 5 }}
          />
          <Text style={{ color: "white" }} small b>
            Potential Coast User
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
  const successTwo = () => (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: "#4f41f1", borderTopWidth: 3 },
      ]}
    >
      <View style={{ width: 200, justifyContent: "space-between" }}>
        <View style={{ marginBottom: 25 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: "black",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
                marginTop: 6,
              }}
            >
              <Ionicons name="person" size={11} color="white" />
            </View>
            <Text h4>Acme, Inc.</Text>
          </View>
          <Text style={{ color: "grey" }} font="11px">
            _id: 'ent_msb8G2Qm7sZDd'
          </Text>
        </View>

        <Card style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}>
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",

              marginBottom: 25,
            }}
          >
            <Image
              source={{
                uri: "https://i.ibb.co/k1BG3KK/Coast-Logo-White.png",
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "black",
              }}
              resizeMode="contain"
            />
            <Text style={{ color: "grey" }} small b font="12px">
              Action
            </Text>
            <Text style={{ marginBottom: -10, textAlign: "center" }} h4>
              Received Demo
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: "#4f41f1",
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              flexDirection: "row",
            }}
          >
            <Ionicons
              name="ios-checkmark"
              size={24}
              color="white"
              style={{ marginRight: 5 }}
            />
            <Text style={{ color: "white" }} small b>
              Interested
            </Text>
          </TouchableOpacity>
        </Card>
      </View>
    </View>
  );
  const successThree = () => (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: "#4f41f1", borderTopWidth: 3 },
      ]}
    >
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Card
          style={{
            width: 250,
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <Image
              source={{
                uri: "https://images.unsplash.com/photo-1644657154979-459f43a01d7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 80,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 0.5,
                borderColor: "lightgrey",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              Sales Team
            </Text>
            <Text style={{ marginBottom: 5, textAlign: "center" }} h4>
              Acme, Inc.
            </Text>
            <Text style={{ color: "grey", marginBottom: 10 }} small>
              Has an interested customer
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: "#4f41f1",
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              flexDirection: "row",
            }}
          >
            <Ionicons
              name="ios-checkmark"
              size={24}
              color="white"
              style={{ marginRight: 5 }}
            />
            <Text style={{ color: "white" }} small b>
              Generates Custom Demo
            </Text>
          </TouchableOpacity>
        </Card>

        <View
          style={{
            alignSelf: "center",
            marginVertical: 10,
            width: 125,
            borderTopWidth: 1,
            borderTopColor: "grey",
            borderStyle: "dashed",
            height: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "#4f41f1",
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 6,
              paddingHorizontal: 15,
              alignSelf: "center",
              borderRadius: 50,
            }}
          >
            <Text style={{ color: "white" }} small b>
              Coast
            </Text>
          </View>
        </View>

        <Card style={{ width: 300, alignSelf: "center", marginBottom: 25 }}>
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",

              marginBottom: 25,
            }}
          >
            <Image
              source={{
                uri: "https://images.unsplash.com/photo-1646282014691-620f9af448a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              Demo
            </Text>
            <Text style={{ marginBottom: -10, textAlign: "center" }} h4>
              Your Sales Lead
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: "#4f41f1",
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              flexDirection: "row",
            }}
          >
            <Ionicons
              name="ios-checkmark"
              size={24}
              color="white"
              style={{ marginRight: 5 }}
            />
            <Text style={{ color: "white" }} small b>
              Converted Sales Lead
            </Text>
          </TouchableOpacity>
        </Card>
      </View>
    </View>
  );

  const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "55%" }}>
          <Text style={{ color: "#D9D9D9", marginBottom: -10 }} h1>
            Example Goal
          </Text>
          <Text style={{ color: "#242424", marginBottom: -10 }} h1>
            Show Your User's Journey
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: "#8C8C8C" }} font="16px">
            This is where you can provide the high-level overview for your
            customer to understand how to use your API to accomplish their
            goals. Show that you understand the problem they aim to solve and
            why your tech is uniquely qualified to be their solution. We can
            generate these on the fly, so they're custom each time!
          </Text>
        </View>
        <View style={{ alignSelf: "flex-end" }}>
          <Button
            type="success"
            style={{ fontWeight: "bold" }}
            onClick={() =>
              // lastComp.current.scrollIntoView({
              //   behavior: "smooth",
              // })
              {
                setAnimating(true);
              }
            }
          >
            Press me!
          </Button>
        </View>
      </View>
      <View style={{ marginVertical: 20 }} />
      {/* <JsxParser
        bindings={{
          styles,
        }}
        components={{
          View,
          ScrollView,
          motion,
          ActivityIndicator,
          Text,
          Button,
          Tag,
          TouchableOpacity,
          Card,
          Input,
        }}
        jsx={component}
      /> */}
      <View style={styles.scrollContainer}>
        <ScrollView
          ref={scrollRef}
          horizontal
          contentContainerStyle={styles.animationContainer}
        >
          <motion.div style={{ alignSelf: "center" }}>
            {successOne()}
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
          >
            <View style={[styles.nextBorder, { width: 30 }]} />
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              // if (animating)
              //   scrollRef.current.scrollBy({
              //     top: 0,
              //     left: +1000,
              //     behavior: "smooth",
              //   });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1 }}
            style={{ alignSelf: "center" }}
          >
            {successTwo()}
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              if (animating)
                scrollRef.current.scrollBy({
                  top: 0,
                  left: +1000,
                  behavior: "smooth",
                });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 3 }}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 4 }}
            style={{ alignSelf: "center" }}
          >
            {successThree()}
          </motion.div>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
  },
  animationContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
    paddingVertical: 40,
    paddingHorizontal: 25,
  },
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
  nextBorder: {
    height: 0,
    width: 75,
    borderTopWidth: 1,
    borderTopColor: "grey",
    borderStyle: "dashed",
    alignSelf: "center",
    marginHorizontal: 20,
  },
  // ...JSON.parse(loadedStyles),
});

export default Goal;
