import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import generateGradient from "../functions/generateGradient";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const calcTabs = (content) => {
  const tabs = [];

  if (content.letter != null && content.letter != "") {
    tabs.push({
      order: 0,
      type: 0,
      title: "Overview",
    });
  }

  for (let i = 0; i < content.length; i++) {
    tabs.push({
      order: tabs.length,
      type: 1, // GOAL
      title: content[i].goalTitle,
      content: content[i].goal,
    });

    const goalOrder = tabs.length - 1;
    let stepOrder = 0;

    const stepArr = content[i].steps;
    for (let ii = 0; ii < stepArr.length; ii++) {
      tabs.push({
        order: tabs.length,
        type: 2, // STEP
        goalOrder: goalOrder,
        stepNumber: stepOrder,
        title: stepArr[ii].stepTitle,
        description: stepArr[ii].stepDescription,
        content: stepArr[ii].step,
      });
      stepOrder++;
    }
  }

  tabs.push({
    order: tabs.length,
    type: 3, // NEXT STEPS
    title: "Next Steps",
    content: content.nextSteps,
  });

  return tabs;
};

const genGradients = (number) => {
  let gradients = [];
  for (let i = 0; i < number; i++) {
    gradients.push(generateGradient());
  }
  return gradients;
};

const planReducer = (state, action) => {
  switch (action.type) {
    case "fetch_plan":
      return {
        ...state,
        plan: action.payload,
        tabs: calcTabs(action.payload.content),
        gradients: genGradients(calcTabs(action.payload.content).length),
      };
    case "select_tab":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "reset":
      return {
        plan: null,
        selectedTab: 0,
      };
    default:
      return state;
  }
};

const fetchPlan = (dispatch) => async (planId) => {
  try {
    const response = await serverApi.post("/plan", { planId: planId });
    dispatch({ type: "fetch_plan", payload: response.data });
  } catch (err) {
    onErr(err, "fetchPlan");
  }
};

const selectTab = (dispatch) => async (tab) => {
  dispatch({ type: "select_tab", payload: tab });
};

const resetPlanContext = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  planReducer,
  {
    fetchPlan,
    selectTab,
    resetPlanContext,
  },
  {
    plan: {
      company: "example-method-id",
      prospectCompany: "Acme, Inc.",
      prospectName: "John",
      letter: "Example letter",
      content: [
        {
          goalTitle: "Get Started with Coast",
        },
      ],
    },
    tabs: [
      {
        order: 0,
        type: 0,
        title: "Overview",
      },
      {
        order: 1,
        type: 1,
        title: "Learn about Coast",
      },
      {
        order: 2,
        type: 2,
        goalOrder: 1,
        stepNumber: 1,
        title: "Show off an endpoint",
        dbTitle: "endpoint",
        description: `Let your code shine. This is not the time to show how to integrate your API... we're in sales mode! We help you sell your API, how easy it is, and everything you can do with a single call. Coast puts that on full display with our API Visualizer: show off everything that's happening in the background when a request is made and display beautiful success UIs, custom fit to any use case. Try it out!`,
        content: {
          type: 1,
          codeSnippet: `curl https://dev.sandbox.com/example-endpoint
          -X POST 
          -H "Authorization: Bearer sk_MIGfMA0GCSqGSIb3DQEB" 
          -H "Content-Type: application/json" 
          -d '{
                "type": "insert-any-data-vals",
                "company": {
                  "picture_keyword": "basketball",
                  "primary_color": "red",
                  "piglatin_translate": "Enter any text to translate into PigLatin..."
                }
              }'`,
          requestFields: [
            {
              key: "picture_keyword",
              label: "Picture Keyword",
              value: "basketball",
              type: 0,
            },
            {
              key: "primary_color",
              label: "Color",
              value: "red",
              type: 0,
            },
            {
              key: "piglatin_translate",
              label: "Enter any text to translate into PigLatin...",
              value: "Coast will change your sales game.",
              type: 0,
              multiline: true,
            },
          ],
          botDetails: [
            {
              title: "Looking up Acme, Inc.",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Comments",
                  value: "Looks Cool",
                },
              ],
            },
            {
              title: "Grabbing a Picture",
              sub: [
                {
                  key: "Status",
                  value: "Clipped it",
                },
              ],
            },
            {
              title: "Translating into PigLatin",
              sub: [
                {
                  key: "Status",
                  value: "Boom!",
                },
              ],
            },
          ],
          successCode: `<View
          style={[
            styles.databaseView,
            { borderTopColor: localDatabase.endpoint.primary_color, borderTopWidth: 3 },
          ]}
          >
          <View style={{ width: 300, justifyContent: "space-between" }}>
            <View
              style={{
                backgroundColor: "#DEFFF6",
                paddingVertical: 8,
                paddingHorizontal: 15,
                borderRadius: 50,
                position: "absolute",
                top: 0,
                left: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: 50,
                  backgroundColor: localDatabase.endpoint.primary_color,
                  marginRight: 6,
                }}
              />
              <Text style={{ color: localDatabase.endpoint.primary_color }} small b font="10px">
                Active
              </Text>
            </View>
            <View
              style={{
                alignSelf: "center",
                alignItems: "center",
                marginBottom: 25,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 50,
                  backgroundColor: "black",
                  marginBottom: 15,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Ionicons name="person-outline" size={24} color=localDatabase.endpoint.primary_color />
              </View>
              <Text style={{ marginBottom: -10, textAlign: "center" }} h4>
                {localDatabase.endpoint.name}
              </Text>
            </View>
          
            <Card style={{ width: 300, alignSelf: "center", marginBottom: 25 }}>
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",

              marginBottom: 25,
            }}
          >
            <Image
              source={{
                uri: localDatabase.endpoint.picture_keyword,
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              Piglatin Translation
            </Text>
            <Text style={{ marginBottom: -10, textAlign: "center" }} h4>
              {localDatabase.endpoint.piglatin_translate}
            </Text>
          </View>
     
        </Card>
          
            <TouchableOpacity
              style={{
                backgroundColor: localDatabase.endpoint.primary_color,
                width: "100%",
                paddingVertical: 8,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                flexDirection: "row",
              }}
            >
              <Ionicons
                name="ios-checkmark"
                size={24}
                color="white"
                style={{ marginRight: 5 }}
              />
              <Text style={{ color: "white" }} small b>
                Executed Successfully
              </Text>
            </TouchableOpacity>
          </View>
          </View>`,
        },
      },
      {
        order: 3,
        type: 2,
        goalOrder: 1,
        stepNumber: 2,
        title: "Choose your own adventure",
        dbTitle: "select",
        description: `With Coast, you can give your user's choices to interact with the API every step of the way. You can load in options from a database or endpoint that returns an array, and let them dictate their own experience. Every session spins up a unique local database, so all of their selections persist and be used in other places on the page. See what we mean by selecting your favorite fast food chain to the right!`,
        content: {
          type: 2,
          data: [
            {
              name: "Starbucks",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/starbucksred.jpg?itok=shhZf9fU",
              ticker: "SBUX",
            },
            {
              name: "McDonald's",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/story/dol-issues-final-rule-joint-employer-saga.jpg?itok=kygK-pF8",
              ticker: "MCD",
            },
            {
              name: "Dunkin'",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/news-image/dunkin-promotes-scott-murphy-president-americas.jpg?itok=HcLzDnKv",
              ticker: "DNKN",
            },
            {
              name: "Pizza Hut",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/cheesey.jpg?itok=FB7Om361",
              ticker: "YUM",
            },
            {
              name: "Burger King",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/whopper.jpg?itok=6f3hBNaK",
              ticker: "QSR",
            },
            {
              name: "Wendy's",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/wendys.jpg?itok=aaPYvSzj",
              ticker: "WEN",
            },
            {
              name: "Taco Bell",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/doritos.jpg?itok=XmyE_J3H",
              ticker: "YUM",
            },
            {
              name: "Domino's",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/news-image/dominos-599-mix-and-match-deal-set-big-game-rush.jpg?itok=FZ6mZKKY",
              ticker: "DPZ",
            },
            {
              name: "Dairy Queen",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/dqgrill-and-chill.jpg?itok=0-qEY0pm",
              ticker: "BRK.A",
            },
            {
              name: "KFC",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/KFC_1.jpg?itok=AokxElpm",
              ticker: "YUM",
            },
            {
              name: "Papa John's",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/story/papa-john-s-founder-john-schnatter-suing-creative-firms.jpg?itok=b0Hu1W7M",
              ticker: "PZZA",
            },
            {
              name: "Baskin-Robbins",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/baskin2.jpg?itok=hqV35C-V",
              ticker: "DNKN",
            },
            {
              name: "Chipotle",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/story/which-colleges-eat-most-chipotle.jpg?itok=6kCRenYR",
              ticker: "CMG",
            },
            {
              name: "Popeyes",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/popeyes.jpg?itok=iU99zpcm",
              ticker: "PLKI",
            },
            {
              name: "Jack in the Box",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/story/jack-box-ceo-lenny-comma-leave-company.jpg?itok=xmxAq_bN",
              ticker: "JACK",
            },
            {
              name: "Panera Bread",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/panera_0.jpg?itok=io4xshZx",
              ticker: "PNRA",
            },
            {
              name: "Auntie Anne's",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/auntie.jpg?itok=L0oWFsVe",
              ticker: "JJSF",
            },
            {
              name: "Wingstop",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/wingstop.jpg?itok=svZteexb",
              ticker: "WING",
            },
            {
              name: "Firehouse Subs",
              logo: "https://www.qsrmagazine.com/sites/default/files/styles/slideshow_image/public/slideshow-images/slides/firehouse2_0.jpg?itok=zKmQkFh3",
              ticker: "QSR",
            },
          ],
        },
      },
      {
        order: 4,
        type: 2,
        goalOrder: 1,
        stepNumber: 3,
        title: "Dynamic Content",
        dbTitle: "value",
        description: `Ah, so you're into {localDatabase.select && localDatabase.select.name ? localDatabase.select.name : null}? Let's take a look at their current stock info. You can go back and select another chain to see it all update. Is it time to invest?`,
        emptyDescription:
          "Once you select your favorite fast food chain above we'll populate this section accordingly!",
        content: {
          type: 3,
        },
      },
      {
        order: 5,
        type: 2,
        goalOrder: 1,
        stepNumber: 4,
        title: "Interact with Results",
        dbTitle: "filter",
        description: `In this step, we're still sticking with your choice of {localDatabase.select && localDatabase.select.name ? localDatabase.select.name : null} and retrieving relevant articles that you can filter through. Try it out! Time to give that BizOps rep something they can look at to actually see the value of your API by playing with it.`,
        emptyDescription:
          "Once you select your favorite fast food chain above we'll populate this section accordingly!",
        content: {
          type: 4,
        },
      },
      {
        order: 6,
        type: 4,
        title: "Customizations",
        description: `Maybe you want to let users upload their own multimedia to execute some action. As an example, `,
      },
      {
        order: 7,
        type: 3,
        title: "Next Steps",
        description: `Maybe you want to let users upload their own multimedia to execute some action. As an example, `,
      },
      // {
      //   order: 5,
      //   type: 2,
      //   goalOrder: 1,
      //   stepNumber: 4,
      //   title: "Filter Payload",
      //   dbTitle: "filter",
      //   description: `Once all the building blocks are set up (entities and liabilities), executing the payments is a walk in the park! Payments are created to transfer funds from one account to another. This could either be between two ACH accounts, or from an ACH account to a Liability account. In Acme, Inc.'s case, this will be from your source ACH account to the user's liability account.`,
      //   content: {
      //     type: 2,
      //   },
      // },
    ], // FIXME: remove and set to null for prod
    selectedTab: {
      index: 0,
      type: "auto",
    },
    gradients: genGradients(7),
  }
);
