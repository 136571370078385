import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import StickyBox from "react-sticky-box";
import Endpoint from "./StepComps/EndpointComponent/Endpoint";
import LocalSelect from "./StepComps/LocalComponent/LocalSelect";
import LocalDisplay from "./StepComps/LocalComponent/LocalDisplay";
import _ from "lodash";
import LocalFilter from "./StepComps/LocalComponent/LocalFilter";

const Step = ({ isVisible, order, scrollRef, isScrolling }) => {
  const {
    state: { tabs },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);
  const [preloadKeys, setPreloadKeys] = useState(null);
  const windowDims = useWindowDimensions();
  const stepContent = tabs[order];

  useEffect(() => {
    const checkPreload = () => {
      const reqFields =
        tabs[order].content != null && tabs[order].content.requestFields != null
          ? tabs[order].content.requestFields
          : null;
      if (reqFields != null) {
        const filteredFields = reqFields.filter(
          (elem) =>
            elem.hasOwnProperty("preloadKey") & (elem.preloadKey != null)
        );
        if (filteredFields.length > 0) {
          setPreloadKeys(filteredFields);
        }
      }
    };
    checkPreload();
  }, []);

  const tableValues = () =>
    preloadKeys.map((elem) => {
      return {
        key: elem.key,
        description:
          _.get(localDatabase, elem.preloadKey) != null
            ? _.get(localDatabase, elem.preloadDesc)
            : elem.desc,
        value:
          _.get(localDatabase, elem.preloadKey) != null
            ? _.get(localDatabase, elem.preloadKey)
            : "Not Selected",
      };
    });

  const evalDescription = (text) => {
    //const matches = text.match(/\{(.*?)}/);
    const matches = text.match(/\{([^)]*)\}/);

    if (matches != null && matches.length > 0) {
      const evaluatedText = eval(matches[1]);
      if (evaluatedText == null) {
        return stepContent.emptyDescription;
      } else {
        return text.replace(matches[0], evaluatedText);
      }
    }

    return text;
  };

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <StickyBox offsetTop={50} offsetBottom={20}>
          <Text style={{ color: "#D9D9D9", lineHeight: 1.1 }} h1>
            Step {stepContent.stepNumber}
          </Text>
          <Text style={{ color: "#242424", lineHeight: 1.1 }} h1>
            {stepContent.title}
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: "#8C8C8C" }} font="16px">
            {evalDescription(stepContent.description)}
          </Text>

          {preloadKeys != null && preloadKeys.length > 0 ? (
            <View>
              <View style={{ marginVertical: 15 }} />
              <Card>
                <Text h5>Dynamic Choices</Text>
                <Text style={{ color: "grey" }} small>
                  Carried values from previous steps
                </Text>
                <View style={{ marginVertical: 10 }} />
                <Table data={tableValues()}>
                  <Table.Column prop="key" label="Key" />
                  <Table.Column prop="description" label="Label" />
                  <Table.Column prop="value" label="Value" />
                </Table>
              </Card>
            </View>
          ) : null}
        </StickyBox>
      </View>

      <View style={styles.contentContainer}>
        {stepContent.content.type == 1 ? (
          <Endpoint
            order={order}
            stepContent={stepContent}
            scrollRef={scrollRef}
            isScrolling={isScrolling}
          />
        ) : null}
        {stepContent.content.type == 2 ? (
          <LocalSelect order={order} stepContent={stepContent} />
        ) : null}
        {stepContent.content.type == 3 ? (
          <LocalDisplay order={order} stepContent={stepContent} />
        ) : null}
        {stepContent.content.type == 4 ? (
          <LocalFilter order={order} stepContent={stepContent} />
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textContainer: {
    width: "30%",
  },
  contentContainer: {
    width: "65%",
    paddingBottom: 150,
  },
});

export default Step;
