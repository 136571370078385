import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Text } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Cpu } from "@geist-ui/icons";

const OverviewContent = () => {
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const goalComp = (text, color) => (
    <View style={[styles.goalContainer, { marginBottom: -10 }]}>
      <Cpu color={color} />
      <Text style={{ color: "black", marginLeft: 10 }} p>
        {text}
      </Text>
    </View>
  );

  const stepComp = (text, color) => (
    <View style={[styles.goalContainer, { marginLeft: 30, marginTop: 18 }]}>
      <View
        style={{
          height: 8,
          width: 8,
          borderRadius: 20,
          backgroundColor: color,
        }}
      />
      <Text style={{ color: "#303030", marginLeft: 10 }} small>
        {text}
      </Text>
    </View>
  );

  return (
    <View style={styles.overview}>
      <View>
        <Text style={{ color: "white" }} small>
          Acme, Inc.'s unique Sandbox API Key
        </Text>
        <View style={styles.keyField}>
          <Text style={{ color: "black" }} small>
            sk_S838JK5Jdnf86vsoe332jjIO
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.overviewBox,
          {
            background:
              "linear-gradient(" +
              150 +
              "deg, " +
              "white" +
              ", " +
              "rgba(248,248,248,0.9)" +
              ")",
          },
        ]}
      >
        <Text style={{ color: "#3a7bd5" }} h5>
          Acme, Inc. Integration Overview
        </Text>
        <Text style={{ color: "#303030" }} small>
          One goal, four steps
        </Text>
        <View style={{ marginVertical: 10 }} />
        {goalComp("Learn about Coast", "#00d2ff")}
        {stepComp("Show off an endpoint", "#00d2ff")}
        {stepComp("Choose your own adventure", "#00d2ff")}
        {stepComp("Dynamic Content", "#00d2ff")}
        {stepComp("Interact with Results", "#00d2ff")}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  keyField: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 6,
    marginTop: 10,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  overviewBox: {
    marginTop: 20,
    width: "100%",
    padding: 40,
    borderRadius: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  boxBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    borderRadius: 6,
  },
  goalContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 2,
  },
  goalCircle: {
    height: 18,
    width: 18,
    borderRadius: 100,
    marginRight: 10,
  },
  stepSquare: {
    height: 8,
    width: 8,
    marginRight: 12,
  },
});

export default OverviewContent;
