import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  Text,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import Sidebar from "./Sidebar/Sidebar";
import Content from "./Content/Content";
import colors from "@res/colors";
import fonts from "@res/fonts";

const HomeScreen = ({ navigation, route }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View style={[styles.container, { width: window.width, height: "100vh" }]}>
      <View style={{ width: sidebarCollapsed ? 80 : 300, height: "100%" }}>
        <Sidebar
          collapsed={sidebarCollapsed}
          setCollapsed={setSidebarCollapsed}
        />
      </View>
      <View
        style={{
          width: sidebarCollapsed ? window.width - 80 : window.width - 300,
          height: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Content />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#0e0026",
    flexDirection: "row",
  },
});

export default HomeScreen;
