import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import axios from "axios";
import PigLatin from "pig-latinizer";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const codeReducer = (state, action) => {
  switch (action.type) {
    case "convert_request":
      return {
        ...state,
        convertedRequest: action.payload,
      };
    case "execute_request":
      return {
        ...state,
        requestResponse: action.payload,
      };
    default:
      return state;
  }
};

const convertRequest =
  (dispatch) =>
  async ({ requestCode, language, order }) => {
    try {
      const response = await serverApi.post("/convert-request", {
        requestCode,
        language,
      });
      dispatch({
        type: "convert_request",
        payload: { code: response.data, order: order },
      });
    } catch (err) {
      onErr(err, "convertRequest");
    }
  };

const setConvertedRequest = (dispatch) => (code) => {
  dispatch({ type: "convert_request", payload: code });
};

const executeRequest =
  (dispatch) =>
  async ({ requestCode, localTitle }) => {
    try {
      /******
       *
       * If you have no path in your logic that allows one person to publish code to be used by others then you can use eval() as it is.
       * Your situation is not anyhow different from any user that has browser and dev tools in it where he/she can run any code they want.
       *
       */
      let requestColor;
      let requestKeyword;
      let requestTranslate;

      if (Array.isArray(requestCode)) {
        requestColor = requestCode.find(
          (elem) => `${elem.key}` == "primary_color"
        ).value;
        requestKeyword = requestCode.find(
          (elem) => `${elem.key}` == "picture_keyword"
        ).value;
        requestTranslate = requestCode.find(
          (elem) => `${elem.key}` == "piglatin_translate"
        ).value;
      } else {
        requestColor = requestCode.primary_color;
        requestKeyword = requestCode.picture_keyword;
        requestTranslate = requestCode.piglatin_translate;
      }

      const unsplashResponse = await axios.get(
        `https://api.unsplash.com/search/photos/?client_id=fjgQZal19f59iXeiZ68y3JUzMmDxFHz3_ML_RwvH4kI&query=${encodeURI(
          requestKeyword
        )}`
      );
      const unsplashData = unsplashResponse.data;
      const unsplashPhoto = unsplashData.results[0].urls.regular;

      const pigLatin = new PigLatin();
      pigLatin.exclusions.push("woot");
      const piglatinText = pigLatin.translate(requestTranslate);
      console.log(piglatinText);
      toast("Easy, huh? 😎");

      const payload = {
        data: {
          primary_color: requestColor,
          picture_keyword: unsplashPhoto,
          piglatin_translate: piglatinText,
        },
        localTitle: localTitle,
      };

      dispatch({
        type: "execute_request",
        payload: payload,
      });
    } catch (err) {
      onErr(err, "executeRequest");
    }
  };

export const { Provider, Context } = createDataContext(
  codeReducer,
  {
    convertRequest,
    setConvertedRequest,
    executeRequest,
  },
  {
    convertedRequest: null,
    requestResponse: null,
  }
);
